import axios from 'axios';

export default async function (handle, selectedVariantId = null) {
    const response = (await axios.get(`/products/${handle}`, {
        params: {
            view: 'object-json',
            variant: selectedVariantId
        }
    }))?.data;

    const html = document.createElement('div');
    html.innerHTML = response;
    return JSON.parse(html.querySelector('[data-json-response]').innerHTML);
}
